.grid_gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1px;

    .col {
        width: 100%;
        flex: 0 0 auto;
        padding: 0 1px;
        margin-top: 2px;

        &:nth-child(1) {
            margin-top: 0;
        }
        
        @include mq(xs) {
            width: 50%;

            &:nth-child(2) {
                margin-top: 0px;
            }
        }
    
        @include mq(md) {
            width: 33.33%;

            &:nth-child(3) {
                margin-top: 0px;
            }
        }
    }
}
