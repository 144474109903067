.block {
    & + & {
        margin-top: 3rem;
    }
}

.calendar {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}

.grid_facilities {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;

    .col {
        padding: 0 16px;
        width: 100%;
        margin-top: 32px;

        &:nth-child(1) {
            margin-top: 0;
        }

        @include mq(sm) {
            width: 50%;
            
            &:nth-child(2) {
                margin-top: 0;
            }
        }

        @include mq(md) {
            width: 33.33%;

            &:nth-child(3) {
                margin-top: 0;
            }
        }
    }
}

.facility_header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 16px;
    }

    img {
        width: 48px;
        height: 48px;
    }
}

.facility_body {
    ul {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    li {
        padding: 16px 0;

        &:nth-child(n+2) {
            border-top: 1px solid #ccc;
        }
    }
}