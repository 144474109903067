.container {
    display: block;
    width: auto;
    max-width: map-get($breakpoints, xxl);
    margin: 0 auto;
    padding: 0 24px;
}

.container_small {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.site_title {
    font-family: 'Dancing Script', serif;
    font-size: rem(36px);
    font-weight: bold;
}

.grid_33_66 {
    display: flex;
    flex-direction: column;
    margin: 0 -24px;

    @include mq(lg) {
        flex-direction: row;
    }

    .col_33 {
        padding: 32px 24px;

        @include mq(lg) {
            width: 33.33%;
        }
    }

    .col_66 {
        padding: 0 24px;

        @include mq(lg) {
            width: 66.66%;
        }
    }
}