.nav_main {
    position: relative;
}

.nav_main_button {
    width: 48px;
    height: 48px;
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq(md) {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    svg {
        width: 17px;
        height: 17px;
    }
}

.nav_main_list {
    display: none;
    flex-direction: column;
    width: auto;
    min-width: 240px;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #fff;
    border-radius: 8px;
    //box-shadow: 0 4px 16px rgba(#000, .1);
    position: absolute;
    z-index: 99;
    top: 100%;
    right: -8px;

    &.is-active {
        display: flex;
    }

    @include mq(md) {
        display: flex;
        flex-direction: row;
        min-width: 0;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        position: static;
        top: auto;
        left: auto;
    }

    li {
        &:nth-child(n+2) {
            border-top: 1px solid #dedede;

            @include mq(md) {
                border-top: 0;
                margin-left: 24px;
            }
        }
    }

    a {
        display: block;
        padding: 16px 24px;
        white-space: nowrap;

        @include mq(md) {
            display: inline;
            padding: 0;
        }
    }

    .is-active a {
        font-weight: bold;
        
        // @include mq(md) {
        //     box-shadow: inset 0 -.5rem 0 #f0f0f0;
        //     padding: 0 4px;
        // }
    }
}