.contact_row {
    display: flex;
    align-items: center;
}

.contact_row_icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

.map {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    background-color: #f0f0f0;
}