html {
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-size: 16px;
  line-height: 175%;
}

*, 
*:before, 
*:after {
  box-sizing: border-box;
}

body {
  background-color: #c2e8e7;
}

h1, h2, h3, 
h4, h5, h6, 
p, ol, ul {
  margin: 0 0 1rem;
  font-weight: normal;

  .rt &:last-child {
    margin-bottom: 0;
  }
}

h1, h2, h3, 
h4, h5, h6 {
  line-height: 150%;

  * + & {
    margin-top: 2.5rem;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  fill: $theme-font-color;
}

a {
  text-decoration: none;
  color: inherit;
}

.rt a {
  padding: 0 4px;
  font-weight: bold;
  text-decoration: underline;
  //box-shadow: inset 0 -.5rem 0 #f0f0f0;
  //transition: box-shadow 0.25s ease;

  // &:hover {
  //   box-shadow: inset 0 -.5rem 0 transparent;
  // }
}

button {
  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
  }
}
