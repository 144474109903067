.slideshow {
    overflow: hidden;

    ul {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        flex: 0 0 auto;
        width: 100%;
    }

    nav {
        display: flex;
        margin-top: 24px;
    }
}

.slideshow_prev,
.slideshow_next {
    display: inline;
    margin: 0 8px;
    padding: 0 4px;
    border: 0;
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    background-color: transparent;
    text-decoration: underline;
}
