/*
 * Breakpoints
 */

$breakpoints: (
    xxs: 320px,
    xs: 480px,
    sm: 640px,
    md: 800px,
    lg: 960px,
    xl: 1120px,
    xxl: 1280px
);

/*
 * Fonts
 */

$theme-font-family: 'Source Sans Pro', sans-serif;
$theme-font-size: rem(16px);
$theme-font-weight: normal;
$theme-font-line-height: 1.75;
$theme-font-color: #000;

/*
 * Colors
 */